import { render, staticRenderFns } from "./SdsZinc.vue?vue&type=template&id=011858e4&scoped=true&"
import script from "./SdsZinc.vue?vue&type=script&lang=js&"
export * from "./SdsZinc.vue?vue&type=script&lang=js&"
import style0 from "./SdsZinc.vue?vue&type=style&index=0&id=011858e4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "011858e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCheckbox,VForm,VRadio,VRadioGroup,VTextField})
